
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import BaseDial from '@/components/Base/BaseDial.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { investPools } from '@/apis/ApiPool';
import { numberToMoney, moneyToNumber } from '@/utils/index';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import constRouter from '@/constants/constRouter';
import * as Analytics from '@/services/analytics';
export default defineComponent({
  components: {
    DefaultLayout,
    // BaseDial,
    BaseButton,
    BaseInput,
    BaseTextError,
  },
  data() {
    return {
      numberToMoney,
      amount: '',
      errorMessages: '',
      loading: false,
    };
  },
  watch: {
    amount(value) {
      if (value) {
        value = moneyToNumber(value);
      }
      this.amount = numberToMoney(value);
    },
  },
  computed: {
    ...mapGetters({
      packageChoose: 'getPackage',
    }),
  },
  created() {
    if (!this.packageChoose.name && !this.packageChoose.id) {
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    }
  },
  methods: {
    ...mapActions({
      fetchListHistoryInvest: 'fetchListHistoryInvest',
    }),
    ...mapMutations({
      setInvestmentInfo: 'setInvestmentInfo',
      setHistoryInvestment: 'SET_HISTORY_INVESETMENT',
      setInvestmentPage: 'SET_INVESETMENT_PAGE',
      setPackage: 'setPackage',
    }),
    inputAmount(num: number) {
      this.amount = this.amount + num;
    },
    clearAmount() {
      this.amount = this.amount.slice(0, -1);
    },
    chooseAmount(val: string) {
      this.amount = val;
    },
    onSubmit() {
      this.errorMessages = '';
      let val = moneyToNumber(this.amount);
      if (!val) {
        this.errorMessages = 'Bạn chưa nhập số tiền';
        return;
      }
      if (val < 100000) {
        this.errorMessages = 'Số tiền tối thiểu là 100.000đ';
        return;
      }
      this.loading = true;
      investPools(this.packageChoose.id, {
        amount: val,
      })
        .then((res) => {
          this.setInvestmentInfo({
            ...res.data,
            isSuccess: true,
          });
          this.loading = false;
          if (this.$route.query.params === 'bank') {
            this.$router.push({
              name: constRouter.BANK_ACCOUNT.name,
            });
          } else {
            this.setHistoryInvestment([]);
            this.setInvestmentPage(1);
            this.setPackage({});
            this.fetchListHistoryInvest();
            this.$router.push({
              name: constRouter.INVESTMENT_SUCCESS.name,
            });
          }
        })
        .catch((err) => {
          this.setInvestmentInfo({ isSuccess: false });
          console.log(err);
        });
      Analytics.eventK2A(
        'INVESTMENT',
        'INVEST_INPUT_MOENY',
        'Nhập só tiền đầu tư',
        val,
      );
    },
  },
});
